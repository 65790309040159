import { get, set, del } from 'idb-keyval'
import { PersistedClient, Persister } from '@tanstack/react-query-persist-client'
import { asyncThrottle } from '../../utils/asyncThrottle'

interface StorageManager<T> {
  get(): Promise<T | undefined>
  set(value: T): Promise<void>
  del(): Promise<void>
}

// This is a workaround for the fact that IndexedDB is not supported in the browser
// For instance, Apple Mail
const getStorageManager = (idbValidKey: string): StorageManager<PersistedClient> => {
  if ('indexedDB' in window) {
    return {
      get: async () => get(idbValidKey),
      set: async v => set(idbValidKey, v),
      del: async () => del(idbValidKey),
    }
  }
  const storage = {
    [idbValidKey]: undefined as PersistedClient | undefined,
  }
  return {
    get: async () => storage[idbValidKey],
    set: async v => {
      storage[idbValidKey] = v
    },
    del: async () => {
      storage[idbValidKey] = undefined
    },
  }
}

/**
 * Creates an Indexed DB persister
 * @see https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API
 */
export function createIDBPersister(idbValidKey: string = 'reactQuery') {
  const manager = getStorageManager(idbValidKey)
  const persist = async (client: PersistedClient) => {
    await manager.set(client)
  }
  return {
    persistClient: asyncThrottle(persist, { interval: 500 }),
    restoreClient: async () => {
      return await manager.get()
    },
    removeClient: async () => {
      await manager.del()
    },
  } as Persister
}
