import _ from 'lodash'

export const stringsToObj = strings => _.reduce(strings, (result, e) => _.merge(result, { [e]: e }), {})

export const objToQueryParamsString = (obj = {}) => {
  const params = new URLSearchParams()
  _.each(obj, (v, k) => {
    if (_.isArray(v)) {
      _.each(v, _v => {
        params.append(k, _v)
      })
    } else if (v === undefined) {
      return
    } else {
      params.append(k, v)
    }
  })
  return params.toString()
}

export const unsafeObjToQueryParams = _.flow([
  _.toPairs,
  _.partial(_.map, _, ([k, v]) => `${k}=${v}`),
  _.partial(_.join, _, '&'),
])

export const queryParamsStringToObj = queryString => {
  const sp = new URLSearchParams(queryString)
  // sp.entries() is a Symbol.Iterator, so we have to spread it in order to iterate with lodash
  return _.reduce(
    [...sp.entries()],
    (acc, v) => {
      return _.set(acc, v[0], v[1])
    },
    {}
  )
}

export const getInitial = n => {
  if (_.isString(n) && n.length > 0) {
    return _.toUpper(n[0])
  }
  return ''
}

/**
 * Swap key and value. Be careful, just use it when values are strings/numbers
 * @param obj
 * @returns {{}}
 */
export const reverseObj = obj =>
  _.reduce(
    obj,
    (acc, v, k) => {
      try {
        acc[v] = k
      } catch (err) {
        console.warn('error reversing obj')
      }
      return acc
    },
    {}
  )

export const safeCallFn = (fn, ...args) => _.isFunction(fn) && fn(...args)

export const Fns = {
  equals: _.curry(_.isEqual),
}
