import { QueryClient } from '@tanstack/react-query'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { FC, PropsWithChildren, useMemo } from 'react'
import { createIDBPersister } from './idbPersister'
import { isApptest } from '../../helpers/queryParamsHelper'
import { getCookie } from '../../utils/cookies'
import { SHOW_REACT_QUERY_DEVTOOL } from '@/environment'

export const queryClient = new QueryClient({
  defaultOptions: {
    // @ts-ignore
    gcTime: 1000 * 60 * 60 * 24 * 7, //a week
  },
})

const MAX_AGE = 1000 * 60 * 60 * 24 * 7

type IProps = PropsWithChildren

const useSessionKey = () => {
  return useMemo(() => {
    const getDefault = () => 'anonymous#' + new Date().getTime()
    return getCookie('sessionid_validation') ?? window.prioritymatrix.sessionToken ?? getDefault()
  }, [])
}

const PersistWhitelist = new Set(['config', 'me', 'projectSummaries'])

export const ReactQueryProvider: FC<IProps> = ({ children }) => {
  const appName = isApptest() ? 'apptest' : 'app'
  const persistKey = ['rq', appName].join('-')
  const persister = useMemo(() => createIDBPersister(persistKey), [persistKey])
  const buster = [useSessionKey()].join('-')

  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{
        persister,
        dehydrateOptions: {
          shouldDehydrateMutation: () => false,
          shouldDehydrateQuery: q => {
            if (q.state.status === 'success') {
              const firstParam = q.queryKey[0] as string
              if (PersistWhitelist.has(firstParam)) {
                return true
              }
            }
            return false
          },
        },
        maxAge: MAX_AGE,
        buster,
      }}
    >
      {children}

      {SHOW_REACT_QUERY_DEVTOOL && <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />}
    </PersistQueryClientProvider>
  )
}
