import { PLATFORMS } from '../common/src/constants'
import _ from 'lodash'

export const QUERY_PARAMS = {
  MS_TEAMS: 'msteams',
  OUTLOOK: 'outlook',
  PLATFORM: 'platform',
  EMBEDDED: 'embedded',
  REMEMBER: 'remember',
  NO_REDIRECT: 'noredirect',
  FAST_PROJECT: 'fastproject',
  ANDROID: 'android',
  IOS: 'ios',
  ONE_ON_ONE_CREATOR: 'one_on_one_creator',
  ONE_ON_ONE_FIXED: 'one_on_one_fixed',
  SESSION_TOKEN: 'sessiontoken',
  HIDE_HEADER: 'hide_header',
  ONBOARDING: 'onboarding',
  LOGOUT: 'logout',
  IN_MEETING: 'in_meeting',
  NAVIGATED: 'navigated',
  PAYWALL_NEXT: 'paywall_next',
  HIDE_CHROME: 'hide_chrome',
  TAG_FILTER: 'tag_filter',
  FILTER_PRESET: 'filter_preset',
  FROM_NOTIFICATION: 'from_notification',
  COMMERCIAL: 'commercial',
}

export const decodeEmailComponent = _.flow([decodeURIComponent, _.toLower])

export const isWindows = (queryString = window.location.search) => {
  const sp = new URLSearchParams(queryString)
  const platform = sp.get(QUERY_PARAMS.PLATFORM)
  return _.isString(platform) && _.toLower(platform) === PLATFORMS.WINDOWS
}

export const isEmbeddedOnTeams = (queryString = window.location.search) => {
  const sp = new URLSearchParams(queryString)
  return !!sp.get(QUERY_PARAMS.MS_TEAMS)
}

export const isEmbeddedOnAndroid = () => {
  return !!navigator.userAgent.includes('PMAndroidExpo')
}

export const isEmbeddedOnIos = () => {
  return !!navigator.userAgent.includes('PMIosExpo')
}

export const isEmbeddedOnMobile = () => {
  return isEmbeddedOnAndroid() || isEmbeddedOnIos()
}

export const isEmbeddedOnOutlook = (queryString = window.location.search) => {
  const sp = new URLSearchParams(queryString)
  return !!sp.get(QUERY_PARAMS.OUTLOOK)
}

export const isEmbedded = (queryString = window.location.search) => {
  const sp = new URLSearchParams(queryString)
  return !!sp.get(QUERY_PARAMS.EMBEDDED)
}

export const getRemember = (queryString = window.location.search) => {
  const sp = new URLSearchParams(queryString)
  return _.toSafeInteger(sp.get(QUERY_PARAMS.REMEMBER))
}

export const hasNoRedirect = (queryString = window.location.search) => {
  const sp = new URLSearchParams(queryString)
  return sp.has(QUERY_PARAMS.NO_REDIRECT)
}

export const getSearchParamsWithNoRedirect = (queryString = window.location.search) => {
  const sp = new URLSearchParams(queryString)
  sp.set(QUERY_PARAMS.NO_REDIRECT, 1)
  return '?' + sp.toString()
}

export const getOneOnOneCreatorQueryParam = (queryString = window.location.search) => {
  const sp = new URLSearchParams(queryString)
  const creator = sp.get(QUERY_PARAMS.ONE_ON_ONE_CREATOR)
  return creator && decodeEmailComponent(creator)
}

export const isOneOnOneFixedQueryParam = (queryString = window.location.search) => {
  const sp = new URLSearchParams(queryString)
  return sp.has(QUERY_PARAMS.ONE_ON_ONE_FIXED)
}

export const shouldHideHeader = (queryString = window.location.search) => {
  const sp = new URLSearchParams(queryString)
  return sp.get(QUERY_PARAMS.HIDE_HEADER) === '1'
}

export const getFastProject = (queryString = window.location.search) => {
  const sp = new URLSearchParams(queryString)
  let fp = sp.get(QUERY_PARAMS.FAST_PROJECT)
  // readonly link redirection is not working fine on iframes
  // we need to check hash as well
  if (!fp) {
    const hash = window.location.hash
    const hashQuery = hash.substr(1)
    const hashSp = new URLSearchParams(hashQuery)
    fp = hashSp.get(QUERY_PARAMS.FAST_PROJECT)
  }
  return fp || void 0
}

export const showOnboarding = (queryString = window.location.search) => {
  const sp = new URLSearchParams(queryString)
  return !!sp.get(QUERY_PARAMS.ONBOARDING)
}

export const getNextQueryParamFromCurrentLocation = (wasSignedOut = false) => {
  const queryString = window.location.search
  const sp = new URLSearchParams(queryString)
  if (wasSignedOut && !sp.has(QUERY_PARAMS.LOGOUT)) {
    sp.set(QUERY_PARAMS.LOGOUT, '1')
  } else if (!wasSignedOut && sp.has(QUERY_PARAMS.LOGOUT)) {
    sp.delete(QUERY_PARAMS.LOGOUT)
  }
  const uriComponent = window.location.pathname + `?${sp.toString()}`
  return encodeURIComponent(uriComponent)
}

export const isInMeeting = (queryString = window.location.search) => {
  const sp = new URLSearchParams(queryString)
  return !!sp.get(QUERY_PARAMS.IN_MEETING)
}

export const wasNavigated = (queryString = window.location.search) => {
  const sp = new URLSearchParams(queryString)
  return !!sp.get(QUERY_PARAMS.NAVIGATED)
}

export const getPaywallNext = (queryString = window.location.search) => {
  const sp = new URLSearchParams(queryString)
  return sp.get(QUERY_PARAMS.PAYWALL_NEXT)
}

export const getSearchParamsWithNavigated = (queryString = window.location.search) => {
  const sp = new URLSearchParams(queryString)
  sp.set(QUERY_PARAMS.NAVIGATED, 1)
  return '?' + sp.toString()
}

export const hideChrome = (queryString = window.location.search) => {
  const sp = new URLSearchParams(queryString)
  return !!sp.get(QUERY_PARAMS.HIDE_CHROME)
}

export const fromNotification = (queryString = window.location.search) => {
  const sp = new URLSearchParams(queryString)
  return !!sp.get(QUERY_PARAMS.FROM_NOTIFICATION)
}

export const getSearchParamsByTag = (queryString = window.location.search) => {
  const sp = new URLSearchParams(queryString)
  return sp.get(QUERY_PARAMS.TAG_FILTER)
}

export const getCommercialNotificationId = (queryString = window.location.search) => {
  const sp = new URLSearchParams(queryString)
  return sp.get(QUERY_PARAMS.COMMERCIAL)
}

export const isApptest = () => window.location.href.includes('apptest')
