export function getCookie(cname: string) {
  const name = cname + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const cookieList = decodedCookie.split(';')

  for (let c of cookieList) {
    c = c.trimStart()
    if (c.startsWith(name)) {
      return c.substring(name.length, c.length)
    }
  }
  return undefined
}
