import { getSentry } from './getSentry'
import './polyfills'
import './startup'
import { createRoot } from 'react-dom/client'
import React, { Suspense } from 'react'
import { retry } from './common/src/retry'
import { ReactQueryProvider } from './queries/react/configureReactQuery'

const App = React.lazy(() => retry(() => import('./App')))

import('./startup-lazy.js')

const Sentry = getSentry()

const Core = () => (
  <ReactQueryProvider>
    <Suspense fallback={<></>}>
      <App />
    </Suspense>
  </ReactQueryProvider>
)

try {
  const container = document.getElementById('root')
  const root = createRoot(container)
  root.render(<Core />)
} catch (err) {
  Sentry.withScope(scope => {
    scope.setExtra('info', 'Unable to mount React node')
    Sentry.captureException(err)
  })
}
